<template>
  <div>
    <ViewTemplateWithTable :panel="panel" :templateList="templateList" :buttonRemove="false">
      <div slot="content-buttons">
        <Button _key="btnNfeCreate" title="Nfe" classIcon="fas fa-plus-circle" type="primary" size="small"
          eventName="nfeCreate" :clicked="create" />
      </div>
      <div slot="content-buttons-table-header">
        <Button _key="btnGenerateNfe" :title="'Gerar ' + selected.length" type="primary" size="small"
          :clicked="confirmGenerate" />
      </div>
    </ViewTemplateWithTable>
    <CancelNfse />
    <Modal title="Tem certeza que deseja gerar?" :width="500" v-show="showModal('generateNfe')">
      <Alert type="warning">
        <span>
          Atenção, certifique-se que está gerando a Nota Fiscal correta, após gerada
          não será permitido alterações
        </span>
      </Alert>
      <Confirmation :isModal="false" title="Você tem certeza?" type="primary" :confirmed="generate" />
    </Modal>
  </div>
</template>

<script>
import ViewTemplateWithTable from "@nixweb/nixloc-ui/src/component/template/ViewTemplateWithTable.vue";
import Confirmation from "@nixweb/nixloc-ui/src/component/shared/Confirmation.vue";
import Alert from "@nixweb/nixloc-ui/src/component/layout/Alert";
import Modal from "@nixweb/nixloc-ui/src/component/forms/Modal";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";

import CancelNfse from "./CancelNfse.vue";

import { mapGetters, mapMutations, mapActions, mapState } from "vuex";

export default {
  name: "NfeListView",
  components: {
    ViewTemplateWithTable,
    Confirmation,
    Alert,
    Modal,
    Button,
    CancelNfse,
  },
  data() {
    return {
      id: "",
      panel: {
        module: "Nota Fiscal",
        title: "NFe",
        showFilter: true,
        showSearch: true,
        showButtons: true,
      },
      templateList: {
        urlGetApi: "/api/v1/tax-document/nfe/get-all",
        urlGenerateAllApi: "/api/v1/tax-document/nfe/generate",
        urlDeleteAllApi: "/api/v1/tax-document/nfe/cancel",
        showChecks: true,
        headerTable: [
          {
            title: "Número",
            field: "number",
            type: "text",
            iconSearch: false,
          },
          {
            title: "Emissão",
            field: "registeredDate",
            type: "dateTime",
            iconSearch: false,
            classCssTitle: "text-center",
            classCssBody: "text-center",
          },
          {
            field: "fullName",
            title: "Cliente",
            type: "link",
            routeName: "nfeUpdate",
            iconSearch: true,
          },
          {
            field: "statusName",
            title: "Status",
            type: "class",
            fieldComparison: "statusName",
            classCssTitle: "text-center",
            classCssBody: [
              {
                classCss: "text-center badge badge-warning",
                fieldComparison: "Pendente",
              },
              {
                classCss: "text-center badge badge-info",
                fieldComparison: "Em processamento",
              },
              {
                classCss: "text-center badge badge-primary",
                fieldComparison: "Autorizada",
              },
              {
                classCss: "text-center badge badge-success",
                fieldComparison: "Concluída",
              },
              {
                classCss: "text-center badge badge-danger",
                fieldComparison: "Cancelada",
              },
              {
                classCss: "text-center badge badge-danger",
                fieldComparison: "Rejeitada",
              },
            ],
          },
          {
            title: "Valor",
            field: "value",
            classCssTitle: "text-center",
            classCssBody: "text-center",
            type: "currency",
          },
          {
            type: "button",
            field: "situacao",
            ifFieldEqual: "Autorizada",
            typeButton: "default",
            button: {
              classIcon: "fa-regular fa-ban",
              type: "danger",
              size: "small",
              eventName: "cancelNfse",
            },
            classCssTitle: "text-center",
            classCssBody: "text-center",
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters("generic", ["showModal", "event"]),
    ...mapState("generic", ["selected"]),
  },
  methods: {
    ...mapMutations("generic", ["openModal", "hideModal", "removeLoading"]),
    ...mapActions("generic", ["putAllApi", "deleteAllApi"]),
    confirmGenerate() {
      this.removeLoading(["btnGenerateNfe"]);
      this.openModal("generateNfe");
    },
    generate() {
      let params = {
        url: this.templateList.urlGenerateAllApi,
        selected: this.selected,
      };
      this.putAllApi(params).then((response) => {
        if (response.success) {
          this.hideModal("generateNfe");
        }
        this.removeLoading(["confirm"]);
      });
    },
    create() {
      this.$router.push({
        name: "nfeCreate",
      });
      this.removeLoading(["btnNfeCreate"]);
    },
  },
};
</script>
